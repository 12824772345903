import React from 'react';
import Form from '../components/Form';
import Page from '../components/Page';
import Row from '../components/Row';
import { Search } from '../components/Search';
import Section from '../components/Section';
import { Heading } from '../components/Text';
import useSiteMetadata from '../hooks/useSiteMetadata';

export default function Home(props: any) {
  const meta = useSiteMetadata();

  return (
    <Page {...props}>
      <Row alignItems="center" justifyContent="center" flexDirection="column">
        <Section>
          <Row alignItems="center" justifyContent="center">
            <Heading.h1>{meta.title}</Heading.h1>
          </Row>
        </Section>
        <Search />
      </Row>
    </Page>
  );
}
