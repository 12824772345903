import React, { useEffect, useState } from 'react';
import Form from '../Form';
import { useInputState } from '../Form/hooks';
import Row from '../Row';
import Section from '../Section';
import { container, icon, input } from './Search.module.css';
import searchIcon from '../../assets/search-icon.png';
import PostList from '../PostList/PostList';
import { findPosts, IPost } from '../../requests/findPosts';
import useFindPosts from '../../hooks/useFindPosts';
import { Body } from '../Text';

export default function Search() {
  const search = useInputState();
  const posts = useFindPosts({ text: search.html.value });

  return (
    <>
      <Row alignItems="center" justifyContent="center" flexWrap="wrap">
        <Section.Div className={container}>
          <Form.Input {...search.html} className={input}></Form.Input>
          <img
            src={searchIcon}
            className={icon}
            alt="Search"
            title="Search site"
          />
        </Section.Div>
      </Row>
      <Row
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="column"
      >
        <Section.Div className={container}>
          {posts.length > 0 ? (
            <PostList posts={posts} />
          ) : (
            <Body.p>
              Software engineering topics, questions, guides, docs and examples
              in as few words as possible
            </Body.p>
          )}
        </Section.Div>
      </Row>
    </>
  );
}
