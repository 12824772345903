import React from 'react';
import cn from 'classnames';
import {
  input,
  textarea,
  button,
  noBorderStyle,
  codeTextArea,
} from './Form.module.css';
import CodeBlock from '../CodeBlock';
import Row from '../Row';
import Section from '../Section';
import { CodeFormState } from './hooks';
import { Link } from 'gatsby';
import { Heading } from '../Text';

interface FormProps {
  className?: string;
  children: React.ReactNode;
}

export default function Form() {
  return null;
}

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  noBorder?: boolean;
}

Form.Input = ({
  className = '',
  type = 'text',
  noBorder = false,
  ...props
}: InputProps) => (
  <input
    className={cn(input, {
      [className]: !!className,
      [noBorderStyle]: noBorder,
    })}
    type={type}
    {...props}
  />
);

interface SelectInputProps extends React.HTMLProps<HTMLSelectElement> {
  className?: string;
  noBorder?: boolean;
  options?: string[];
}

Form.SelectInput = ({
  className = '',
  noBorder = false,
  options,
  ...props
}: SelectInputProps) => (
  <select
    className={cn(input, {
      [className]: !!className,
      [noBorderStyle]: noBorder,
    })}
    {...props}
  >
    {options?.map((option) => (
      <option
        key={option}
        value={option}
        onClick={(e) => props?.onChange?.(e as any)}
      >
        {option}
      </option>
    ))}
  </select>
);

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string;
  noBorder?: boolean;
}

Form.TextArea = ({
  className = '',
  noBorder = false,
  ...props
}: TextAreaProps) => (
  <textarea
    className={cn(textarea, {
      [className]: !!className,
      [noBorderStyle]: noBorder,
    })}
    {...props}
  />
);

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
}

Form.Button = ({ className = '', children, ...props }: ButtonProps) => (
  <button className={cn(button, className)} {...props}>
    {children}
  </button>
);

interface ButtonLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  className?: string;
  to?: string;
}

Form.ButtonLink = ({ className = '', children, ...props }: ButtonLinkProps) =>
  props.to ? (
    <Link className={cn(button, className)} {...props}>
      {children}
    </Link>
  ) : (
    <a className={cn(button, className)} {...props}>
      {children}
    </a>
  );

interface CodeFormProps extends CodeFormState {
  className?: string;
}

Form.Code = ({ className = '', ...props }: CodeFormProps) => (
  <>
    <Section>
      <Form.TextArea {...props.preTextInput} />
    </Section>
    <Section>
      <Form.SelectInput {...props.langInput} />
    </Section>
    <Section>
      <Form.TextArea
        className={cn(textarea, {
          [className]: !!className,
          [codeTextArea]: codeTextArea!!,
        })}
        {...props.textarea}
      />
    </Section>
    <CodeBlock code={props.code} language={props.lang} />
    <Section>
      <Form.TextArea {...props.postTextInput} />
    </Section>
  </>
);
