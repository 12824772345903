import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { IPost } from '../requests/findPosts';

export default function useFindPosts({ text }: { text: string }): IPost[] {
  const [posts, setPosts] = useState<IPost[]>([]);
  const { allSitePage } = useStaticQuery(
    graphql`
      query findPosts {
        allSitePage {
          edges {
            node {
              pageContext
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (text === '') {
      setPosts([]);
      return;
    }
    const nextPosts: IPost[] = [];
    allSitePage.edges.forEach(({ node }: any) => {
      const context = node.pageContext;
      const parsed = context;
      if (!parsed.uuid) return;

      const values = Object.values(parsed);
      const valueString = values.join('').toLowerCase();

      if (valueString.indexOf(text.toLowerCase()) > -1) {
        nextPosts.push(parsed);
      }
    });

    setPosts(nextPosts);
  }, [text]);

  return posts;
}
