import React, { useState, useCallback, useMemo } from 'react';
import { langs } from './langs';

interface OptionsProps {
  title?: string;
  placeholder?: string;
  initialState?: any;
}

export function useInputState<T = HTMLInputElement>(options?: OptionsProps) {
  const [value, setter] = useState(options?.initialState || '');
  const onChange = useCallback((e: React.ChangeEvent<T>) => {
    e.preventDefault();
    setter(e.target.value);
  }, []);

  return useMemo(
    () => ({
      html: {
        title: options?.title || undefined,
        placeholder: options?.placeholder || undefined,
        value,
        onChange,
      },
      reset: () => setter(''),
      setValue: (v: string) => setter(v),
    }),
    [value, onChange]
  );
}

// https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
const languageOptions = [...langs, 'html', 'js', 'ts'];

const languageMappings = (lang: string) => {
  const langLower = lang.toLowerCase();
  switch (langLower) {
    case 'html':
      return 'xml';
    case 'js':
      return 'javascript';
    case 'ts':
      return 'typescript';
    default:
      return lang;
  }
};

interface CodeOptionsProps {
  initialValue?: any;
  initialLanguage?: any;
}

export interface CodeFormState {
  textarea: any;
  langInput: any;
  preTextInput: any;
  postTextInput: any;
  code: string;
  lang: string;
  preText: string;
  postText: string;
  data: {
    code: string;
    language: string;
    preText: string;
    postText: string;
  };
  reset: () => void;
  setCode: (code: string) => void;
  setLang: (lang: string) => void;
  setPreText: (text: string) => void;
  setPostText: (text: string) => void;
}

export function useCodeFormState(options?: CodeOptionsProps): CodeFormState {
  const [code, setCode] = useState(options?.initialValue || '');
  const [lang, setLang] = useState(options?.initialLanguage || 'js');
  const [preText, setPreText] = useState('');
  const [postText, setPostText] = useState('');

  const onCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setCode(e.target.value);
    },
    []
  );

  const onLangChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLang(e.target.value);
  }, []);

  const onPreTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setPreText(e.target.value);
    },
    []
  );

  const onPostTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setPostText(e.target.value);
    },
    []
  );

  return {
    code,
    lang: languageMappings(lang),
    preText,
    postText,
    data: {
      code,
      language: languageMappings(lang),
      preText,
      postText,
    },
    textarea: {
      placeholder: '* Enter code...',
      value: code,
      onChange: onCodeChange,
    },
    langInput: {
      options: languageOptions,
      onChange: onLangChange,
      title: 'Code language',
      value: lang,
      placeholder: '* Enter code lang...',
    },
    preTextInput: {
      onChange: onPreTextChange,
      title: 'Pre text',
      value: preText,
      placeholder: 'Enter pre text (not required)',
    },
    postTextInput: {
      onChange: onPostTextChange,
      title: 'Post text',
      value: postText,
      placeholder: 'Enter post text (not required)',
    },
    reset: () => {
      setCode('');
      setLang('');
    },
    setCode,
    setLang,
    setPreText,
    setPostText,
  };
}
